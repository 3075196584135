<template>
    <RightSidePanel title="Your summary" :isLoading="isLoadingQuotes">
        <template v-for="(jewelry, i) in getJewelries">
            <b-col :key="i" class="jewelries" cols="12">
                <div class="f-between">
                    <div>
                        <span class="blue-dot"></span>
                        <span class="name font-weight-bold">{{ jewelry.name }}</span>
                        <div class="price x2p">
                            Valued at:
                            <DecimalPrice :price="jewelry.value" />
                        </div>
                    </div>
                    <div>
                        <b-icon class="h5 pointer icon" icon="x-circle" @click="remove(i)" />
                    </div>
                </div>
                <div class="f-between x2p">
                    <div class="small-t">
                        <div class="font-weight-bold">Yearly premium:</div>
                        <div>
                            with a
                            <DecimalPrice :price="jewelry.deductible.Deductible" />
                            deductible
                        </div>
                    </div>
                    <div class="small-t font-weight-bold">
                        <DecimalPrice :price="jewelry.deductible.AnnualPremium" />
                    </div>
                </div>
                <div class="x2p mt-2 edit no-select" @click="goInitialStep">
                    Edit Item
                </div>
                <hr class="summary-border" />
            </b-col>
        </template>

        <b-col class="jewelries" cols="12">
            <div class="f-between x2p">
                <div class="text-lead font-weight-light">Total:</div>
                <div>
                    <div class="text-lead font-weight-light">
                        <DecimalPrice :price="previewTotals.total + previewTotals.taxesAndFees" />
                    </div>
                    <div class="text-right">per year</div>
                </div>
            </div>
            <div v-if="previewTotals.minimumEnforced" class="minimum-required">
                This is Jewelers Mutual Group's minimum policy premium.
                <b-icon
                    :id="`show-tooltip-summary-minimum`"
                    class="bootstrap-icon minimum-tooltip-icon"
                    icon="question-circle-fill"
                />
                <Tooltip
                    :target="`show-tooltip-summary-minimum`"
                    title="The minimum premium is the lowest amount insurance regulators allow us to charge based on your state."
                />
            </div>
            <div class="f-between x2p mt-3 align-center sm-diff">
                <div class="add no-select" @click="add">
                    + Add Item
                </div>
                <div>
                    <Button
                        v-if="resultStep === this.quoteStep"
                        text="Apply for coverage"
                        type="button"
                        :disabled="!hasAllQuotes"
                        @clickEvent="apply"
                    />
                    <div v-else class="edit text-capitalize" @click="saveAndFinishLater">
                        Save & Finish Later
                    </div>
                </div>
            </div>
            <div class="x2p mt-5">
                <b-link class="edit text-capitalize" @click="emailMyQuote">Email me my quote</b-link>
            </div>
            <div class="mt-3 ftr">
                Total includes all taxes & fees. Discounts may apply if certain criteria are met. Rate may change if
                wearer criteria is altered.
            </div>
        </b-col>
    </RightSidePanel>
</template>

<script>
import RightSidePanel from '@/components/Common/RightSidePanel';
import DecimalPrice from '@/components/Common/DecimalPrice';
import Button from '@/components/FormGroups/Button';
import Tooltip from '@/components/Common/Tooltip';
import { mapActions, mapGetters } from 'vuex';
import constants from '@/constants';
import axios from 'axios';

export default {
    name: 'RightSideSummary',
    components: { DecimalPrice, RightSidePanel, Button, Tooltip },
    created() {
        this.quoteStep = constants.steps.quote;
    },
    computed: {
        ...mapGetters(['resultStep', 'previewTotals', 'jewelryItems', 'isLoadingQuotes']),
        hasAllQuotes() {
            return !this.jewelryItems.find(item => !item.quotes || !item.quotes.length);
        },
        getJewelries() {
            return this.jewelryItems.map(jewelry => {
                const { quotes, name, value } = jewelry;
                const deductible = (quotes || []).find(d => d.selected) || {};

                return {
                    name,
                    value,
                    deductible
                };
            });
        }
    },
    methods: {
        ...mapActions(['addJewelry', 'removeJewelry']),
        saveAndFinishLater() {
            this.$eventBus.$emit('save');
        },
        emailMyQuote() {
            this.$eventBus.$emit('email');
        },
        apply() {
            this.$eventBus.$emit('apply-for-coverage');
        },
        async add() {
            const payload = {
                mode: 'itemAdded',
                eventCategory: 'Additional Items',
                eventAction: 'Added Item',
                eventValue: this.jewelryItems.length
            };
            await axios.post(`/api/session/segment`, payload);
            this.addJewelry();
            this.goInitialStep();
        },
        goInitialStep() {
            this.$eventBus.$emit('go-initial-step');
        },
        remove(jewelryIndex) {
            if (this.getJewelries.length > 0) {
                this.removeJewelry(jewelryIndex);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.m-mt {
    margin-top: -15px;
}

.summary-border {
    border-bottom: 1px solid $dim-gray;
}

.jewelries {
    text-transform: none;
    padding-right: $margin-under-element + 10px;

    .f-between {
        display: flex;
        justify-content: space-between;

        .dot {
            height: 6px;
            width: 6px;
            background-color: $blue;
            border-radius: 50%;
        }

        .name {
            font-size: 14px;
        }

        .price {
            font-size: 12px;
        }

        .icon {
            position: relative;
            top: 15px;
            color: $dark-gray;
        }

        .small-t {
            font-size: 13px;
        }
    }

    .p {
        margin-left: $margin-under-element - 10px;
    }

    .x2p {
        margin-left: $margin-under-element;
    }

    .edit {
        display: inline-block;
        color: $blue;
        font-weight: 700;
        text-decoration: underline;
        user-select: none;
        cursor: pointer;
    }

    .sm-diff {
        @media (max-width: 991px) {
            flex-direction: column;
        }
    }

    .add {
        display: inline-block;
        color: $blue;
        font-weight: 700;
        user-select: none;
        cursor: pointer;

        @media (max-width: 991px) {
            width: 100%;
            text-align: center;
            margin-bottom: $margin-under-element;
        }
    }
}

.align-center {
    align-items: center;
}

.ftr {
    font-size: 9px;
    margin: auto 0;
}
.minimum-required {
    text-align: center;
    color: red;
    font-size: 9px;
    .minimum-tooltip-icon {
        font-size: 12px;
    }
}
.blue-dot {
    width: 8px;
    height: 8px;
    background-color: #0097d6;
    border-radius: 4px;
    display: inline-block;
    margin-right: 11px;
}
</style>
