<template>
    <RightSidePanel
        v-if="applicantAndWearersForm"
        class="applicant-wearers-right-container"
        title="Applicant & Wearers"
    >
        <b-col class="applicant" cols="6">
            <span>
                Applicant
                <span @click="editApplicantOrWearers">
                    <EditIcon class="h4 ml-2" />
                </span>
            </span>
        </b-col>
        <b-col class="wearers" cols="6">
            <span>
                Other Wearers
                <span @click="editApplicantOrWearers">
                    <EditIcon class="h4 ml-2" />
                </span>
            </span>
        </b-col>
        <b-col cols="12">
            <hr class="my-3 black" />
        </b-col>
        <b-col class="applicant-content" cols="6">
            <div>{{ primaryApplicantName }}</div>
            <div>{{ primaryApplicant.address }}</div>
            <div class="text-lowercase">{{ primaryApplicant.apartmentSuite }}</div>
            <div>{{ primaryApplicant.city }}, {{ primaryApplicant.state }}&nbsp;{{ primaryApplicant.zipCode }}</div>
            <div>{{ primaryApplicant.phoneNumber }}</div>
            <div class="text-lowercase">{{ primaryApplicant.emailAddress }}</div>
        </b-col>
        <b-col class="wearers-content" cols="6">
            <div v-for="(other, i) in otherWearers" :key="i">
                <template v-if="other.wearer.firstName">
                    {{ other.wearer.firstName }}&nbsp;{{ other.wearer.lastName || '' }}
                </template>
            </div>
        </b-col>
    </RightSidePanel>
</template>

<script>
import RightSidePanel from '@/components/Common/RightSidePanel';
import { mapGetters, mapState } from 'vuex';
import constants from '@/constants';
import EditIcon from '@/components/Common/EditIcon';

export default {
    name: 'RightSideApplicantAndWearers',
    components: { EditIcon, RightSidePanel },
    data: () => ({}),
    computed: {
        ...mapState({
            applicantAndWearersForm: state => state.applicantAndWearers
        }),
        ...mapGetters(['resultStep']),
        primaryApplicant() {
            return this.applicantAndWearersForm.applicant;
        },
        otherWearers() {
            return this.applicantAndWearersForm.wearerJewelryItems;
        },
        primaryApplicantName() {
            const { applicant } = this.applicantAndWearersForm;

            if (applicant.firstName) {
                const firstName = applicant.firstName || 'Primary';
                const lastName = applicant.lastName || applicant.firstName ? applicant.lastName : 'Applicant';

                return `${firstName} ${lastName}`;
            }

            return applicant.lastName || 'Primary Applicant';
        }
    },
    methods: {
        editApplicantOrWearers() {
            if (this.resultStep !== constants.steps.applicantAndWearers) {
                this.$router.push('/applicant');
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.applicant-wearers-right-container {
    font-size: 12px;
}

.black {
    border-top-color: $black;
}
</style>
