<template>
    <div>
        <b-modal ref="modal" :title="mode === 'email' ? 'Email Me My Quote' : 'Save & Finish Later'" @ok="submit">
            <template #modal-footer="{ ok, cancel }">
                <div class="footer-buttons">
                    <Button
                        type="submit"
                        :text="mode === 'email' ? 'Email Me My Quote' : 'Save & Finish Later'"
                        @clickEvent="ok()"
                        :disabled="submitting"
                    />
                    <Button type="button" text="Cancel" cancel @clickEvent="cancel()" />
                    <LoadingSpinner v-if="submitting" />
                </div>
            </template>

            <b-row>
                <b-col cols="12" md="8">
                    <TextInput
                        title="First Name"
                        :model="form.firstName"
                        name="first-name"
                        autocomplete="given-name"
                        :validator="required"
                        :show-error="showAllErrors"
                        @inputChanged="v => (form.firstName = v)"
                        @validate="v => this.setValid('firstName', v)"
                    />
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="8">
                    <TextInput
                        title="Last Name"
                        :model="form.lastName"
                        name="last-name"
                        autocomplete="family-name"
                        :validator="required"
                        :show-error="showAllErrors"
                        @inputChanged="v => (form.lastName = v)"
                        @validate="v => this.setValid('lastName', v)"
                    />
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="8">
                    <TextInput
                        title="Email"
                        :model="form.email"
                        name="email"
                        autocomplete="email"
                        :validator="required"
                        :show-error="showAllErrors"
                        @inputChanged="v => (form.email = v)"
                        @validate="v => this.setValid('email', v)"
                    />
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" class="terms">
                    Please review the Jewelers Mutual Group website
                    <b-link href="https://www.jewelersmutual.com/terms-of-use" target="_blank">Terms Of Use</b-link>
                    and
                    <b-link href="https://www.jewelersmutual.com/privacy-policy" target="_blank">Privacy Policy</b-link>
                    In addition, to learn more about how Jewelers Mutual Group protects its customers' nonpublic
                    personal information, including financial information, please read our "Notice to Policyholders: How
                    We Protect Your Privacy" (
                    <b-link href="/privacy-notice" target="_blank">Privacy Notice</b-link>
                    ).
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <b-form-checkbox
                        :model="form.acceptTerms"
                        :value="true"
                        :unchecked-value="false"
                        :state="showAllErrors && !valid.acceptTerms ? false : null"
                        @input="v => this.setValid('acceptTerms', v)"
                    >
                        I agree to the website
                        <b-link target="_blank" href="https://www.jewelersmutual.com/terms-of-use">Terms Of Use</b-link>
                        and
                        <b-link target="_blank" href="https://www.jewelersmutual.com/privacy-policy">
                            Privacy Policy
                        </b-link>
                        , and I have read the
                        <b-link href="/privacy-notice" target="_blank">Privacy Notice</b-link>
                        .
                    </b-form-checkbox>
                </b-col>
            </b-row>
        </b-modal>

        <b-modal ref="confirm" title="Email Me My Quote" @ok="hideConfirm">
            <template #modal-footer="{ ok }">
                <div class="footer-buttons">
                    <Button text="Got it, thanks." @clickEvent="ok()" />
                </div>
            </template>

            <b-row>
                <b-col>Your quote was sent to {{ form.email }}.</b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _get from 'lodash/get';
import TextInput from '@/components/FormGroups/TextInput';
import Button from '@/components/FormGroups/Button';
import LoadingSpinner from '@/components/Common/LoadingSpinner';
import { scrollToError } from '@/utils';

export default {
    name: 'SaveAndFinishLaterModal',
    components: { TextInput, Button, LoadingSpinner },
    data: () => ({
        form: {
            firstName: '',
            lastName: '',
            email: '',
            acceptTerms: false
        },
        valid: {
            firstName: false,
            lastName: false,
            email: false,
            acceptTerms: false
        },
        showAllErrors: false,
        submitting: false,
        submitError: false,
        mode: 'email'
    }),
    computed: {
        ...mapGetters(['previewQuoteLocation', 'applicantAndWearers']),
        isAllValid() {
            return !Object.keys(this.valid).find(field => !this.valid[field]);
        }
    },
    methods: {
        ...mapActions(['saveSession', 'postSegmentData']),
        show(mode = 'save') {
            this.mode = mode;
            if (!this.form.email) {
                this.form.email = _get(this.applicantAndWearers, 'applicant.emailAddress', '');
                this.valid.email = !!this.form.email;
            }
            if (!this.form.firstName) {
                this.form.firstName = _get(this.applicantAndWearers, 'applicant.firstName', '');
                this.valid.firstName = !!this.form.firstName;
            }
            if (!this.form.lastName) {
                this.form.lastName = _get(this.applicantAndWearers, 'applicant.lastName', '');
                this.valid.lastName = !!this.form.lastName;
            }
            this.$refs.modal.show();
        },
        hideConfirm() {
            this.$refs.confirm.hide();
        },
        submit(e) {
            e.preventDefault(); // Prevent modal from hiding
            if (!this.isAllValid) {
                this.showAllErrors = true;
                this.$nextTick(() => scrollToError(this.$refs.form));
                return;
            }

            this.submitting = true;

            this.saveSession({
                firstName: this.form.firstName,
                lastName: this.form.lastName,
                email: this.form.email
            })
                .catch(() => {
                    this.submitError = true;
                })
                .then(() => {
                    this.submitting = false;
                    if (!this.submitError) {
                        this.$refs.modal.hide();
                        this.$refs.confirm.show();
                    }

                    try {
                        this.postSegmentData(this.mode);
                    } catch (e) {
                        console.log(e);
                    }
                });
        },
        required(v) {
            return !!v;
        },
        setValid(field, val) {
            this.valid[field] = val;
        }
    }
};
</script>

<style lang="scss" scoped>
.footer-buttons {
    width: 100%;
    .btn {
        margin-right: 1em;
    }
}
.terms {
    font-size: 14px;
    padding-bottom: 10px;
    line-height: 1.43;
}
</style>
