<template>
    <b-row>
        <Left>
            <ProgressBar :route-step="routeStep" />
            <router-view :key="$route.params.id" />
        </Left>
        <Right>
            <RightSideSummary v-show="resultStep !== initialStep && resultStep !== reviewStep" class="mb-4" />
            <RightSideApplicantAndWearers v-show="applicantAndWearerForm && resultStep < reviewStep" class="mb-4" />
            <Coverage />
        </Right>
        <SaveAndFinishLaterModal ref="saveAndFinishModal" />
    </b-row>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import constants from '@/constants';
import Left from '@/components/Common/Left';
import Right from '@/components/Common/Right';
import Coverage from '@/components/Coverage';
import RightSideSummary from '@/components/RightSideSummary';
import ProgressBar from '@/components/ProgressBar';
import RightSideApplicantAndWearers from '@/components/ApplicantAndWearer/RightSideApplicantAndWearers';
import SaveAndFinishLaterModal from '@/components/Common/SaveAndFinishLaterModal';

export default {
    name: 'Results',
    components: {
        RightSideApplicantAndWearers,
        ProgressBar,
        RightSideSummary,
        Coverage,
        Right,
        Left,
        SaveAndFinishLaterModal
    },
    data: () => ({
        initialStep: constants.steps.initial,
        applicantAndWearersNumber: constants.steps.applicantAndWearers,
        reviewStep: constants.steps.review
    }),
    mounted() {
        window.scrollTo(0, 0);
        this.$eventBus.$on('go-initial-step', payload => {
            if (payload && Array.isArray(payload)) {
                this.addNewJewelryItemsInInitialStep(payload);
            }

            this.$router.push('/').catch(() => {});
        });

        this.$eventBus.$on('email', () => {
            if (this.$refs && this.$refs.saveAndFinishModal && this.$refs.saveAndFinishModal.show) {
                this.$refs.saveAndFinishModal.show('email');
            }
        });
        this.$eventBus.$on('save', () => {
            if (this.$refs && this.$refs.saveAndFinishModal && this.$refs.saveAndFinishModal.show) {
                this.$refs.saveAndFinishModal.show('save');
            }
        });
    },
    computed: {
        ...mapState({
            applicantAndWearerForm: state => state.applicantAndWearers
        }),
        ...mapGetters(['resultStep']),
        routeStep() {
            const { meta } = this.$route;

            return meta.step;
        }
    },
    methods: mapMutations(['addNewJewelryItemsInInitialStep'])
};
</script>
