<template>
    <div class="progress-bar-container">
        <div v-for="(b, i) in bar" :key="b.text" class="d-inline-flex progress-bar-content">
            <div class="progress-icons">
                <div
                    v-if="i > 0"
                    :class="{
                        'blue-background': b.step <= resultStep
                    }"
                    class="progress-line"
                />
                <span
                    v-if="b.step === resultStep"
                    :class="{
                        'blue-background': b.step <= resultStep
                    }"
                    class="icon gray-background icon-active"
                />
                <span
                    :class="{
                        'blue-background': b.step <= resultStep
                    }"
                    class="icon gray-background"
                />
            </div>
            <span
                :class="{
                    'black-text': resultStep === b.step,
                    'blue-text': resultStep > b.step
                }"
                class="mt-3 progress-text"
            >
                {{ b.text }}
            </span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import constants from '@/constants';

export default {
    name: 'ProgressBar',
    props: {
        routeStep: {
            type: Number,
            required: true
        }
    },
    data: () => ({
        bar: [
            { text: 'quote', completed: true, step: constants.steps.quote },
            {
                text: 'applicant & wearers',
                completed: true,
                step: constants.steps.applicantAndWearers
            },
            {
                text: 'jewelry details',
                completed: false,
                step: constants.steps.jewelryDetails
            },
            { text: 'review', completed: false, step: constants.steps.review },
            { text: 'payment', completed: false, step: constants.steps.payment }
        ]
    }),
    computed: mapGetters(['resultStep'])
};
</script>

<style lang="scss" scoped>
$width: 146px;
$icon-width: 14px;
$icon-height: 14px;

.progress-bar-container {
    display: flex;
    margin-bottom: $margin-under-element;
    text-align: center;
    text-transform: uppercase;
    color: $light2-gray;

    .progress-bar-content {
        flex-direction: column;
        align-items: center;
        width: $width;

        .progress-icons {
            padding: 0;
            position: relative;
            height: $icon-height;
            width: $icon-width;
            display: flex;

            .op {
                opacity: 0.2;
                z-index: -1;
                width: $icon-width - 1px;
                height: $icon-width - 1px;
            }

            .icon {
                width: $icon-width;
                height: $icon-height;
                vertical-align: middle;
                border-radius: 50%;
            }

            .icon-active {
                position: absolute;
                width: $icon-width + 8px;
                height: $icon-height + 8px;
                opacity: 0.2;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                margin-left: 0px;
            }

            .progress-line {
                border-top-width: 2px;
                border-top-style: solid;
                position: absolute;
                width: $width;
                top: 50%;
                right: 50%;
                z-index: -1;
                margin: -2px 6px 0 20px;
            }
        }

        .progress-text {
            font-size: 12px;
            font-weight: 700;
        }
    }

    .gray-background {
        background-color: $light2-gray;
        border-color: $light2-gray;
    }

    .blue-background {
        background-color: $pacific-blue;
        border-color: $pacific-blue;
    }

    .blue-text {
        color: $pacific-blue;
    }

    .gray-text {
        color: $light2-gray;
    }

    .black-text {
        color: $black;
    }

    @media (max-width: 1199px) {
        display: none;
    }
}
</style>
