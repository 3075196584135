import { render, staticRenderFns } from "./RightSideApplicantAndWearers.vue?vue&type=template&id=8c8c2916&scoped=true"
import script from "./RightSideApplicantAndWearers.vue?vue&type=script&lang=js"
export * from "./RightSideApplicantAndWearers.vue?vue&type=script&lang=js"
import style0 from "./RightSideApplicantAndWearers.vue?vue&type=style&index=0&id=8c8c2916&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c8c2916",
  null
  
)

export default component.exports